import Utility from "../../../shared/utility.js";
import EncryptUtility from "../../utility/js/encrypt-utility.js";
import AddFavourite from "../../utility/js/addFav.js";
import breadcrumbComp from "../../common/breadcrumb-comp";
import PrinterDetails from "../../common/printer-details";
import materialMovementService from "./material-movement-service.js";
import FilterComp from "@/components/common/filter-comp.vue";
import commonApiService from "@/components/common/js/common-api-service.js";
export default {
  data() {
    return {
      refresh: true,
      showReadyToShip: false,
      backSlash: true,
      actionList: [],
      selectedOrders: [],
      mainPage: "",
      subPage: "",
      totalRecords: 0,
      bu_id: 0,
      pageUrl: "",
      favouriteList: [],
      showAdd: false,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      showBusiness: false,
      showFieldSet: true,
      showLPNActions: false,
      showLPNFieldSet: false,
      showEntireContentsForm: false,
      showTransferContentsForm: false,
      unitLPN: "",
      searchLPNRule: [
        (v) => (v || "").length <= 39 || "Max Length of 39 characters",
        (v) => !!v || "Field is required",
        (value) => /^[0-9]{1,3}(?:-[0-9]{3})*$/.test(value) || "Enter a correct format: 000-000-000-000",
      ],
      max50Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 50 || "Value must be 50 characters or less"],
      lpnSelected: "",
      numberRule: [
        (v) => parseInt(v) > 0 || "Field should be greater than 0",
        (v) => parseInt(v) <= 20 || "Field should be lesser than or equal to 20",
        (v) => parseInt(v) < 2147483647 || "Field should be less than 2,147,483,647",
      ],
      searchKey: "",
      selectedBusiness: "",
      lpnDetailsItems: [],
      lpnText: "",
      lpnNum: "",
      orderNo: "",
      businessList: [],
      orderReadyHeader: [
        { text: "", align: "start", value: "select", class: "primary customwhite--text", sortable: false },
        { text: "Orders To Consolidate", value: "ordno", class: "primary customwhite--text" },
        { text: "Business", value: "business", class: "primary customwhite--text" },
        { text: "Order Type", value: "ordertype", class: "primary customwhite--text" },
      ],
      lpnHeader: [
        { text: "LPN", value: "LPN", class: "primary customwhite--text" },
        { text: "Qty on LPN", value: "Quantity", class: "primary customwhite--text" },
      ],
      selectedLpns: [],
      qty: "",
      lpnList: [],
      toLPN: "",
      uniqueBusiness: [],
      uniqueOrderType: [],
      orderListTemp: [],
      arrayData: [],
      orderList: [],
      disableUnit: true,
      disableQty: true,
      disableToLPN: false,
      orderType: "",
      orderId: "",
      disableSelectOrders: false,
      readytoShipDialog: false,
      showUnitID: false,
      showQuantity: false,
      showSubmit: false,
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    let lpnStatus = await AddFavourite.getLPNDetails(this.userId);
    this.lpnText = lpnStatus[0]?.lpn_info;
    this.lpnNum = lpnStatus[0]?.lpn;
  },
  mounted() {
    this.showForm();
  },
  methods: {
    //To add or remove Favourite issue
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //Reset Function
    resetFunction() {
      this.selectedOrders = [];
      this.showReadyToShip = false;
      this.showBusiness = false;
      this.showFieldSet = true;
      this.showLPNFieldSet = false;
      this.showEntireContentsForm = false;
      this.showTransferContentsForm = false;
      this.showLPNActions = false;
      this.searchKey = "";
      this.selectedBusiness = "";
      this.orderNo = "";
      this.disableUnit = true;
      this.disableQty = true;
      this.disableToLPN = false;
      this.disableSelectOrders = false;
      this.readytoShipDialog = false;
      this.showUnitID = false;
      this.showQuantity = false;
      this.showSubmit = false;
      this.showForm();
    },
    //To check whether input value is number or not
    isNumber(event) {
      let value = Utility.isNumber(event);
      return value;
    },
    // To show Business and rest of the form
    async showForm() {
      this.showBusiness = true;
      let tableObj = {
        userid: this.userId,
        pass: 23
      };
      this.orderList = await materialMovementService.getConsolidationDetails("post", tableObj, false);
      this.orderListTemp = this.orderList;
      this.totalRecords = this.orderList?.length;
      this.filterArrayData();
    },
    selectedLPNValue(d) {
      this.selectedOrders = [];
      this.showLPNActions = true;
      this.lpnSelected = d.LPN;
      this.selectedOrders.push(d);
      this.disableSelectOrders = true;
    },
    // When order is selected
    async selectedOrderNo(item) {
      this.showReadyToShip = true;
      this.showFieldSet = false;
      this.showBusiness = false;
      this.showLPNFieldSet = true;
      this.orderNo = item.ordno;
      this.bu_id = item.bu_id;
      this.orderId = item.order_id;
      this.orderType = item.ordertype;
      this.lpnTableRefresh();
    },
    onClickSearch() {
      let obj = {
        order_id: "123",
        ordertype: "SO",
      };
      this.orderNo = obj.order_id;
      this.selectedOrderNo(obj);
    },
    transferEntireContents() {
      this.toLPN = "";
      this.disableToLPN = false;
      this.showEntireContentsForm = true;
      this.showTransferContentsForm = false;
      this.$refs.toLpnFormComplete.resetValidation();
    },
    async onBlurCompeleteTransfer() {
      let transferObj = {
        from_lpn: this.lpnSelected,
        to_lpn: this.toLPN,
        user_key: this.userId,
      };
      if (this.$refs?.toLpnFormComplete?.validate()) {
        let res = await materialMovementService.postLpnConsolidateToLpn("post", transferObj, false);
        if (res == "Success") {
          this.lpnTableRefresh();
        }
      }
    },
    lpnNumFormat(event, num) {
      this.toLPN = Utility.formatLPNNumber(event, num);
    },
    transferContents() {
      this.toLPN = "";
      this.disableToLPN = false;
      this.showTransferContentsForm = true;
      this.showEntireContentsForm = false;
      this.disableUnit = true;
      this.unitLPN = "";
      this.showUnitID = false;
      this.showQuantity = false;
      this.showSubmit = false;
    },
    filterArrayData() {
      this.uniqueBusiness = [...new Map(this.orderList.map((item) => [item["business"], item.business])).values()];
      this.uniqueOrderType = [...new Map(this.orderList.map((item) => [item["ordertype"], item.ordertype])).values()];
      this.arrayData = [
        { id: 1, label: "Business", array: this.uniqueBusiness },
        { id: 2, label: "Order Type", array: this.uniqueOrderType },
      ];
    },
    async filteredValues(value, type) {
      this.selectedFilterName = value;
      this.selectedFilterId = type;
      const filters = {
        0: () => this.orderListTemp,
        1: (value) => this.orderListTemp.filter((element) => element.business == value),
        2: (value) => this.orderListTemp.filter((element) => element.ordertype == value),
      };
      this.filterValueUpdate(filters[type](value));
    },
    filterValueUpdate(data) {
      this.totalRecords = data.length;
      this.orderList = data;
    },
    async onBlurToLPN(item) {
      let lpnObj = {
        from_lpn: this.lpnSelected,
        to_lpn: item,
        user_key: this.userId,
      };
      if (this.$refs?.lpnToForm?.validate()) {
        let res = await materialMovementService.postSubmitForm("post", lpnObj, false);
        if (res == "Success") {
          this.disableUnit = false;
          this.disableToLPN = true;
          this.showUnitID = true;
          this.showQuantity = false;
          this.showSubmit = false;
        } else {
          this.disableUnit = true;
          this.showUnitID = false;
          this.showQuantity = false;
          this.showSubmit = false;
        }
      }
    },
    async onBlurToUnit(item) {
      let unitObj = {
        from_lpn: this.lpnSelected,
        unit: item,
        user_key: this.userId,
      };
      let res = await materialMovementService.postLpnConsolidateUnitValidate("post", unitObj, false);
      if (res?.length !== 0) {
        this.disableQty = !res[0].enable_qty;
        this.disableUnit = true;
        this.showQuantity = true;
        if (res[0].enable_submit) {
          this.showSubmit = true;
          this.onBlurSubmitQty();
        } else {
          this.showSubmit = false;
        }
      } else {
        this.disableUnit = false;
        this.showQuantity = false;
        this.showSubmit = false;
      }
    },
    //On keydown of quantity
    onBlurQuantity() {
      this.showSubmit = true;
      this.disableQty = true;
      this.onBlurSubmitQty();
    },
    async onBlurSubmitQty() {
      let qtyObj = {
        from_lpn: this.lpnSelected,
        to_lpn: this.toLPN,
        unit: this.unitLPN,
        qty: this.qty ? this.qty : 1,
        user_key: this.userId,
      };
      let res = await materialMovementService.postLpnConsolidateSubmitQty("post", qtyObj, false);
      if (res == "Success") {
        this.lpnTableRefresh();
      } else {
        this.disableQty = false;
      }
    },
    async lpnTableRefresh() {
      let lpnObjList = {
        userid: this.userId,
        pass: 3,
        bu_key: this.bu_id,
        order_key: this.orderId,
        ordertype: this.orderType,
      };
      this.lpnList = await materialMovementService.getConsolidationDetails("post", lpnObjList, false);
      this.selectedOrders = [];
      this.showLPNActions = false;
      this.showEntireContentsForm = false;
      this.showTransferContentsForm = false;
      this.disableSelectOrders = false;
    },
    async readyToShip() {
      let submitObj = {
        ordertype: this.orderType,
        order_key: this.orderId,
        user_key: this.userId,
      };
      let res = await materialMovementService.postOnCompleteTransfer("post", submitObj, true);
      res == "Success" ? this.resetFunction() : "";
    },
    async getLPNNumber(loc) {
      let lpnData = await commonApiService.getNextAvailableLPN("get", this.userId);
      if (lpnData.message !== "NA") {
        this.toLPN = lpnData[0]?.lpn;
        if (loc == "toLpn")
          this.$nextTick(() => {
            this.onBlurToLPN(this.toLPN);
          });
        else
          this.$nextTick(() => {
            this.onBlurCompeleteTransfer(this.toLPN);
          });
      }
    },
    //Ship to button click event
    shipLPN() {
      this.readytoShipDialog = true;
    },
  },
  components: {
    breadcrumbComp,
    PrinterDetails,
    FilterComp,
  },
};
